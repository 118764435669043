.fullWidth {
    max-width: 80%;
}
.fullWidth :global(.modal-body) table thead th {
    z-index: 1;
}
.fullWidth :global(.modal-body) table tbody td:nth-child(2),
.fullWidth :global(.modal-body) table tbody td:last-child {
    position: relative;
    z-index: 0;
}

.fullWidth :global(.modal-footer) button {
    line-height: 1;
}

.fullWidth :global(.modal-footer) :global(button[class*='cancelButton']) {
    order: 1;
}

.error {
    margin: 1rem 0 0;
    color: red;
    text-align: center;
}

@media (max-width: 767px) {
    .fullWidth :global(.modal-footer) {
        flex-direction: column;
    }

    .fullWidth :global(.modal-footer) button {
        margin: 0 0 10px !important;
        line-height: 1.5;
    }
}

@media (max-width: 575px) {
    .fullWidth {
        max-width: 100%;
    }
}
