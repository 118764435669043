.fileData {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: var(--sn-space-4);
}
.fileName {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    color: var(--black);
    font-size: var(--sn-label-lg-font-size);
    font-weight: var(--sn-label-lg-font-weight);
    line-height: var(--sn-label-lg-line-height);
}

.fileName svg {
    color: var(--sn-secondary);
    font-size: 32px;
}

.importDate {
    color: var(--sn-grey-300);
    font-size: var(--sn-label-sm-font-size);
    font-weight: var(--sn-label-sm-font-weight);
    line-height: var(--sn-label-sm-line-height);
}
